<script setup lang="ts">
import Button from '~/components/shared/Base/Button.vue';
import InfoDialog from '~/components/shared/Base/InfoDialog.vue';
import { useSettlementDate } from '~/layers/settlement/composables/useSettlementDate';
import { useSettlementStore } from '~/layers/settlement/stores/settlementStore';

interface Props {
    date: string | null, // Выбранная дата приемки
    time: string | null, // Выбранное время приемки
}

withDefaults(defineProps<Props>(), {
    date: null, // Выбранная дата приемки
    time: null, // Выбранное время приемки
});

const { formatTime, formatDate } = useSettlementDate();

const settlementStore = useSettlementStore();

const model = defineModel<boolean>();

// переходим на экран информации об успешной записи на приемку
const acceptDialogAction = (): void => {
    model.value = false;
    settlementStore.goToNextStep();
};
</script>

<template>
    <InfoDialog
        v-model="model"
        styleClass="date-dialog"
        :showCloseButton="false"
    >
        <template #title>
            <div class="dialog-img">
                <img src="/images/dialog-ok.svg">
            </div>
            <div class="dialog-title">{{ $t('settlement.client.date.dialog.title') }}</div>
        </template>
        <template #content>
            <div class="dialog-text">
                <span>{{ $t('settlement.client.date.dialog.text') }}</span> <span>{{ formatDate(date) }}</span> <span>{{ $t('forms.shared.prepositions.at') }}</span> <span>{{ formatTime(time) }}.</span>
            </div>
        </template>
        <template #actions>
            <Button 
                @click="acceptDialogAction"
                styleClass="dialog-button"
            >
                {{ $t('forms.shared.well') }}
            </Button>
        </template>
    </InfoDialog>
</template>

<style scoped lang="scss">
.dialog-img {
    margin-bottom: 40px;
}

.dialog-text {
    text-align: center;
}

.dialog-button {
    width: 100%;
}
</style>