import type { Result } from "~/types/common/result";
import type { IAcceptanceDates } from "../types/acceptanceDates";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import moment from 'moment';

export const useSettlementDate = () => {
    const { get, post } = useApi();
    const { tryAction } = useSafe();

    const config = useRuntimeConfig();

    // Функция для получения объекта с владельцами
    const getDates = async (accepatanceId: number) : Promise<Result<IAcceptanceDates>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${accepatanceId}/date`;
            const dates = await get<IAcceptanceDates>(url);
            
            return dates;
        });
    };

    // Функция для получения массива времени
    const getTimes = async (accepatanceId: number, date: string | null) : Promise<Result<string[]>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${accepatanceId}/date/${date}/times`;
            const times = await get<string[]>(url);
            return times; 
        });
    };

    // отправка выбранной даты
    const sendDate = async (accepatanceId: number | null, time: string | null) : Promise<Result<void>> => {                                          
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${accepatanceId}/date`;
            return await post(url, { dateTime: time });
        });
    };

    // трансформирует массив дат в формат для калердаря
    const transformDateSlots = (dateSlots: string[]): Date[] => {
        return dateSlots.map(slot => new Date(slot));
    };

    // трансформирует массив времени в формат для селекта
    const transformTimeSlots = (timeSlots: string[]): { type: string | null; name: string }[] => {
        return timeSlots.map(slot => {
            const formattedTime = moment(slot).format('HH:mm');
            return {
                type: slot,
                name: formattedTime
            };
        });
    };

    // форматирует время, убирая секунды
    const formatTime = (fullTime: string | null) : string => {
        if (!fullTime) {
            return '';
        }

        return moment(fullTime).format('HH:mm');;
    };

    // форматирует дату, в день недели + число + месяц + год
    const formatDate = (date: string | null) : string => {
        if (!date) {
            return '';
        }

        // временно без дня недели, так как нужно склонять результат (включая предлоги перед)
        return moment(date).format('DD MMMM YYYY');
    };

    return {
        getDates,
        sendDate,
        getTimes,
        transformDateSlots,
        transformTimeSlots,
        formatTime,
        formatDate,
    };
};